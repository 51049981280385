<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPrayerAndPersonalWitnessing }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcTooltip" :i18n="translations.components" />
      <!-- / Camp Selector -->
      <div class="page-body container mx-med">
        <section id="memoryverse" class="section-0">
          <h4>{{ translations.tcScriptureMemoryVerseOfTheWeek }}</h4>
          <div class="" v-html="this.scriptureVerse"></div>
        </section>
        <section class="section-1">
          <h4>{{ translations.tcPersonalWitnessingEncounters }}</h4>
          <div class="row">
            <div class="col col-6">
              <DateCard
                v-for="(opportunity, index) in opportunities"
                :key="index"
                :obj="opportunity.date"
                :i18n="translations.common"
              >
                <BasicDateCard
                  v-if="translations.common"
                  @Witnessing_clicked="handleWitnessingEncounterClick($event)"
                  :i18n="translations.components"
                  :obj="{
                    title: opportunity.wit_name,
                    description: opportunity.wit_content,
                    schedule: opportunity.wit_name,
                    wit_key: opportunity.wit_key,
                    destination: opportunity.destination,
                    wit_name: opportunity.wit_name,
                    wit_content: opportunity.wit_content,
                    wit_add_date: opportunity.wit_add_date,
                    date: opportunity.date,
                  }"
                />
              </DateCard>
            </div>
            <div class="col col-6">
              <NumberCard
                v-for="(stat, index) in churchVisitsData"
                :key="index"
                :obj="stat"
                :i18n="translations.components"
              />
            </div>
          </div>
          <div class="d-flex button-bar">
            <a href="#" @click.prevent="handleViewMoreClick" class="btn btn-primary btn-w-med mr-4 mt-3 mb-3">{{
              translations.tcViewMore
            }}</a>
            <button @click="handleAddWitnessingEncounterClick" class="btn btn-primary btn-w-med mt-3 mb-3">
              {{ translations.tcAddWitnessingEncounter }}
            </button>
            <span class="sub-text ml-4">{{ translations.tcRecordOpportunityToShareChrist }}</span>
          </div>
        </section>
        <section class="section-3">
          <div class="row">
            <InfoCard v-for="(info, index) in churchMinistryInfo" :key="index" :obj="info" />
          </div>
        </section>
      </div>
      <!-- / Page Body -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import CampSelect from '@/components/CampSelect.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-and-personal-witnessing',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      churchVisitsData: [
        {
          num: 3,
          title: '', // tcWitnessingEncountersByUserLast12Months
        },
        {
          num: 15,
          title: '', // tcWitnessingEncountersByCampLast12Months
        },
      ],
      churchPresentationData: [
        {
          num: '10/52',
          title: 'Personal Workers Testaments You Have Ordered',
          subtitle: 'Past 12 Months',
        },
      ],
      churchMinistryInfo: [
        {
          class: 'col col-6',
          title: 'Prayer Center',
          content: '', // Prayer center description
          link: '/programs/pw/prayer-center-daily',
          label: '', // 'View More'
          img: {
            src: 'svgs/hands-praying.svg',
            alt: '', // 'Prayer Center'
            width: 47,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Program Resources',
          content: '', // Program resources description
          link: '/resources/media-portal',
          label: '', // 'View More'
          img: {
            src: 'svgs/program-resources.svg',
            alt: '', // 'Program Resources'
            width: 64,
            height: 64,
          },
        },
      ],
      opportunities: [],
      scriptureVerse: '',
    }
  },
  components: {
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    securityBanner: SecurityBanner,
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      loaded_opportunities: 'prayerAndWitnessing/opportunities',
      scripture_verse: 'prayerAndWitnessing/scriptureVerse',
      opportunityCountByUser: 'prayerAndWitnessing/opportunityCountByUser',
      opportunityCountByCamp: 'prayerAndWitnessing/opportunityCountByCamp',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return this.$route.path.charAt(this.$route.path.length - 1) === '/'
        ? this.isInMenu(this.$route.path.slice(0, -1))
        : this.isInMenu(this.$route.path)
    },
    loadOpportunities() {
      let oppData = []
      if (!this.loaded_opportunities) {
      } else {
        oppData = this.loaded_opportunities.map((opport, idx) => {
          return {
            date: opport.date,
            wit_add_date: opport.wit_add_date,
            wit_content: opport.wit_content,
            wit_key: opport.wit_key,
            wit_name: opport.wit_name,
            destination: '/programs/pw/view-witnessing-encounter',
          }
        })
      }
      return oppData
    },
  },
  methods: {
    ...mapActions({
      loadRecentOpportunities: 'prayerAndWitnessing/loadRecentOpportunities',
      resetSelectedEncounter: 'prayerAndWitnessing/resetSelectedEncounter',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedEncounterKey: 'prayerAndWitnessing/setSelectedEncounterKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([await this.loadRecentOpportunities()]).then(() => {
          this.opportunities = this.loadOpportunities
          this.scriptureVerse = this.scripture_verse
          this.churchVisitsData[0].num = this.opportunityCountByUser
          this.churchVisitsData[1].num = this.opportunityCountByCamp
        })
      } catch (e) {
        console.error(e)
      } finally {
          this.setLoadingStatus(false)
        }
    },
    async handleAddWitnessingEncounterClick() {
      await Promise.all([await this.resetSelectedEncounter()]).then(() => {
        this.$router.push({
          path: '/programs/pw/add-witnessing-encounter',
        })
      })
    },
    async handleViewMoreClick() {
      this.$router.push({
        path: '/programs/pw/personal-witnessing-encounters',
      })
    },
    async handleWitnessingEncounterClick(evt) {
      await this.setSelectedEncounterKey(evt.wit_key)
      this.$router.push(evt.destination)
    },
    loadTranslations() {
      this.churchVisitsData[0].title = this.translations.tcWitnessingEncountersByUserLast12Months
      this.churchVisitsData[1].title = this.translations.tcWitnessingEncountersByCampLast12Months
      this.churchMinistryInfo[0].title = this.translations.tcPrayerCenterTitle
      this.churchMinistryInfo[0].content = this.translations.tcPrayerCenterDescription
      this.churchMinistryInfo[0].label = this.translations.tcViewMore
      this.churchMinistryInfo[0].img.alt = this.translations.tcPrayerCenterTitle
      this.churchMinistryInfo[1].title = this.translations.tcProgramResourcesTitle
      this.churchMinistryInfo[1].content = this.translations.tcProgramResourcesDescription
      this.churchMinistryInfo[1].label = this.translations.tcViewMore
      this.churchMinistryInfo[1].img.alt = this.translations.tcProgramResourcesTitle
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card', 'security-banner'),
        this.getComponentTranslations('common.days', 'common.months-abbreviations'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
/* eslint-disable */
@import '@/styles/settings.scss';
.church-ministry {
  .page-body {
    margin-top: 68px;
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 36px;
    }
  }
  .section-0 {
    margin-bottom: 60px;
    .quote-verse p {
      font-family: $lora;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 1px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 80px;
    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }
    > .row {
      margin-bottom: 28px;
    }
    .col {
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
      }
      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
    .number-card .content .w-subtitle p:not(.subtitle) {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }
  .section-1 {
    .d-flex.button-bar {
      align-items: center;
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }
    .number-card .wo-subtitle p {
      font-size: 26px;
      line-height: 32px;
    }
    .sub-text {
      font-family: $DIN;
      text-transform: uppercase;
      font-size: 20px;
      @include breakpoint(sm) {
        display: block;
        margin-left: 0 !important;
        margin-top: 30px;
      }
    }
  }
  .section-2 {
    .sub-support {
      .num {
        display: flex;
        align-items: center;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    .number-card {
      align-items: center;
    }
    .row {
      justify-content: center;
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
